// 标识符正则
//export const FlagValilPattern = /^[A-Z_\-0-9]+$/;
// 标识符正则
export const FlagValilPattern = /^[A-Za-z]+\w+$/;
// 程序包标识符正则
export const PkgFlagValil = {
  pattern: /^[A-Z]+[0-9_A-Z]+$/,
  msg: "大写字母、数字、下划线组成并且必须以大写字母开头"
};
export const psnPattern = /^\d{8}$/; // psn验证
export const idPattern = /^\d+$/; // id验证

function validPattern(str, pattern) {
  return pattern.test(str);
}

export const psnPatternValid = str => validPattern(str, psnPattern);
export const idPatternValid = str => validPattern(str, idPattern);

// 产品分类type配置
export const productStacksTypeConfig = [
  { label: "可发布产品", value: 0 },
  { label: "配件", value: 1 }
];

// 获取基础数据的 label
const getConfigDataLabel = (baseData, val, key = "label") => {
  let retVal = baseData.find(item => item.value === val);

  if (retVal) {
    return retVal.label;
  }

  return retVal;
};

// 获取产品分类 label
export const getProductStacksTypeLabel = val =>
  getConfigDataLabel(productStacksTypeConfig, val);

// 产品type 配置
export const productTypeConfig = [
  { label: "测试机", value: 0 },
  { label: "正式机", value: 1 }
];

// 获取产品分类 label
export const getProductTypeLabel = val =>
  getConfigDataLabel(productTypeConfig, val);

// 品牌地区
export const brandRegionConfig = [
  { label: "中国地区", value: "1" },
  { label: "欧洲地区", value: "2" },
  { label: "美洲地区", value: "3" },
  { label: "亚洲地区", value: "4" },
  { label: "其他地区", value: "5" }
];

// 获取品牌地区 label
export const getBrandRegionLabel = val =>
  getConfigDataLabel(brandRegionConfig, val);

// 品牌地区
export const softwarePkgTypeConfig = [
  { label: "销售用软件包", value: 1 },
  { label: "为定制软件包", value: 2 },
  { label: "为推送用软件包", value: 3 }
];

// 获取品牌地区 label
export const getSoftwarePkgTypeLabel = val =>
  getConfigDataLabel(softwarePkgTypeConfig, val);

// 菜单 是否隐藏菜单配置
export const menuShowLinkConfig = [
  { label: "否", value: 1 },
  { label: "是", value: 0 }
];

// 菜单 是否隐藏菜单配置 label
export const getmenuShowLinkLabel = val =>
  getConfigDataLabel(menuShowLinkConfig, val);

// 菜单 是否隐藏菜单配置
export const moduleTypeConfig = [
  { label: "外部", value: 1 },
  { label: "内部", value: 0 }
];

// 菜单 是否隐藏菜单配置 label
export const getModuleTypeConfigLabel = val =>
  getConfigDataLabel(moduleTypeConfig, val);

// 系统
export const SystemTypeConfig = [
  { label: "领世达中心", value: 1 },
  { label: "翻译中心", value: 2 }
];

// 系统  label
export const getSystemTypeConfigLabel = val =>
  getConfigDataLabel(SystemTypeConfig, val);

// 当前系统
export const currentSystemConfig = 1;

// 状态
export const statusConfig = [
  { label: "启用", value: 0 },
  { label: "禁用", value: 9 }
];

// 状态  label
export const getStatusConfigLabel = val =>
  getConfigDataLabel(statusConfig, val);

// 权限类型
export const permissionTypeConfig = [
  { label: "菜单", value: 1 },
  { label: "产品", value: 2 },
  { label: "程序包", value: 3 }
];

// 权限类型  label
export const getPermissionTypeLabel = val =>
  getConfigDataLabel(permissionTypeConfig, val);

// 编码类型
export const codeingTypeConfig = [
  { label: "LF", value: "LF" },
  { label: "RF", value: "RF" }
];

// 编码类型  label
export const getCodeingTypeLabel = val =>
  getConfigDataLabel(codeingTypeConfig, val);

// app 状态
export const appStatusConfig = [
  { label: "启用", value: 0, resLabel: "已启用" },
  { label: "开发中", value: 1, resLabel: "开发中" },
  { label: "禁用", value: 9, resLabel: "已禁用" }
];

// app 状态  label
export const getAppStatusLabel = val =>
  getConfigDataLabel(appStatusConfig, val);

// app 状态  label
export const getAppStatusResLabel = val =>
  getConfigDataLabel(appStatusConfig, val, "resLabel");
