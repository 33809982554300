/*
  用户菜单
*/

import { http } from "@/utils/http";
import type {
  IRemoveApiParams,
  IResponseResult,
  IResponseResultPage
} from "../types";
import { currentSystemConfig } from "@/config/baseData";

// 基础数据接口
export interface IUserMenuBaseItem {
  title: string;
  name: string;
  path: string;
  icon: string;
  rank: any;
  parent_id: any;
  type: any;
  component: string;
  active_path: string;
  show_link: any;
  auths: string;
  keep_alive: any;
  belong: any;
}

// 菜单项接口
export interface IUserMenuItem extends IUserMenuBaseItem {
  id: number;
}

// 修改接口
export type TUserMenuUpdateParam = Partial<IUserMenuBaseItem> & {
  id: number;
};

// 菜单列表接口
export type TUserMenuList = IUserMenuItem[];

// 获取动态路由
export const getAsyncRoutesApi = (
  params: any = { belong: currentSystemConfig }
) => {
  return http.get<IResponseResult, never>("/user/menu/getAsyncRoutes", {
    params
  });
};

// 获取菜单树
export const getMenuAllApi = (params?: any) => {
  return http.get<IResponseResult, any>("/user/menu/all", { params });
};

// 删除菜单
export const menuRemoveApi = (params: IRemoveApiParams) => {
  return http.post<IResponseResult, any>("/user/menu/remove", { data: params });
};

// 创建菜单
export const menuCreateApi = (params: IUserMenuBaseItem) => {
  return http.post<IResponseResult, any>("/user/menu/create", { data: params });
};

// 修改菜单
export const menuUpdateApi = (params: TUserMenuUpdateParam) => {
  return http.post<IResponseResult, any>("/user/menu/update", { data: params });
};

// 批量排序菜单
export const menuBatchSortMenuApi = (params: any) => {
  return http.post<IResponseResult, any>("/user/menu/batchSortMenu", {
    data: params
  });
};
