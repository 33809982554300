import type { IButtonMenu, IDomEditor } from "@wangeditor/editor";
import emitter from "./eventBus";

class MyButtonMenu implements IButtonMenu {
  // TS 语法
  // class MyButtonMenu {                       // JS 语法
  title: string;
  tag: string;
  iconSvg: string;
  constructor() {
    this.title = "引入app"; // 自定义菜单标题
    this.iconSvg =
      '<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M2 2h9v9H2zm2 2v5h5V4zm13.5 0a2.5 2.5 0 1 0 0 5a2.5 2.5 0 0 0 0-5M13 6.5a4.5 4.5 0 1 1 9 0a4.5 4.5 0 0 1-9 0M2 13h9v9H2zm2 2v5h5v-5zm9-2h9v9h-9zm2 2v5h5v-5z"/></svg>'; // 可选
    this.tag = "button";
  }

  // 获取菜单执行时的 value ，用不到则返回空 字符串或 false
  getValue(editor: IDomEditor): string | boolean {
    // TS 语法
    // getValue(editor) {                              // JS 语法
    return "";
  }

  // 菜单是否需要激活（如选中加粗文本，“加粗”菜单会激活），用不到则返回 false
  isActive(editor: IDomEditor): boolean {
    // TS 语法
    // isActive(editor) {                    // JS 语法
    return false;
  }

  // 菜单是否需要禁用（如选中 H1 ，“引用”菜单被禁用），用不到则返回 false
  isDisabled(editor: IDomEditor): boolean {
    // TS 语法
    // isDisabled(editor) {                     // JS 语法
    return false;
  }

  // 点击菜单时触发的函数
  exec(editor: IDomEditor, value: string | boolean) {
    console.log("editor", editor);
    emitter.emit("openDialog");
  }
}

export const menu1Conf = {
  key: "menu-self", // 定义 menu key ：要保证唯一、不重复（重要）
  factory() {
    return new MyButtonMenu(); // 把 `YourMenuClass` 替换为你菜单的 class
  }
};
