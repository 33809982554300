/*
 * @Author: jason
 * @Date: 2024-08-27 20:55:51
 * @LastEditTime: 2024-11-11 16:35:25
 * @LastEditors: jason
 * @Description:
 * @FilePath: \lonsdor-center-backend\src\directives\authPage\index.ts
 *
 */
import { hasAuth, hasPageAuth } from "@/router/utils";
import type { Directive, DirectiveBinding } from "vue";

export const authPage: Directive = {
  mounted(
    el: HTMLElement,
    binding: DirectiveBinding<{
      btnVal: string | Array<string>;
      pageName: string;
    }>
  ) {
    const { btnVal, pageName } = binding.value;
    if (btnVal) {
      !hasPageAuth(btnVal, pageName) && el.parentNode?.removeChild(el);
    } else {
      throw new Error(
        "[Directive: auth]: need auths! Like v-auth=\"{btnVal: 'mro:release:product:menu',pageName: 'mroReleaseProduct'}\""
      );
    }
  }
};
