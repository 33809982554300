import type { Directive, DirectiveBinding } from "vue";

export const reClick: Directive = {
  mounted(el: any, binding: any) {
    el.addEventListener("click", () => {
      if (!el.disabled) {
        el.disabled = true;
        setTimeout(() => {
          el.disabled = false;
        }, binding.value || 3000);
      }
    });
  }
};
