/*
 * @Author: jason
 * @Date: 2024-10-24 16:33:18
 * @LastEditTime: 2024-11-12 10:59:43
 * @LastEditors: jason
 * @Description:
 * @FilePath: \lonsdor-center-backend\src\store\modules\fonticon.ts
 *
 */
import { defineStore } from "pinia";
import { store } from "../utils";
import axios from "axios";

export type glyphsItem = {
  font_class: string;
  icon_id: string;
  name: string;
  unicode: string;
  unicode_decimal: string;
};

export type IconDataType = {
  css_prefix_text: string;
  description: string;
  font_family: string;
  id: string;
  name: string;
  glyphs: glyphsItem[];
};

type StateType = {
  appIconData: IconDataType;
};

export const useFonticonStore = defineStore({
  id: "fonticon",
  state: (): StateType => ({
    // 应用图标
    appIconData: {
      css_prefix_text: "",
      description: "",
      font_family: "",
      id: "",
      name: "",
      glyphs: []
    }
  }),
  actions: {
    /** 登入 */

    async getAppIconFn() {
      let res = await axios.get(
        "https://at.alicdn.com/t/c/font_4555801_gh6zm2ub94p.json?spm=a313x.manage_type_myprojects.i1.6.3bc13a81v0cnYt&file=font_4555801_gh6zm2ub94p.json"
      );
      if (res.data) {
        this.appIconData = res.data;
      }
    }
  }
});

export function useFonticonStoreHook() {
  return useFonticonStore(store);
}
