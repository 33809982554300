
					const modules = import.meta.glob([
  "/mock/asyncRoutes.ts",
  "/mock/login.ts",
  "/mock/refreshToken.ts"
], { eager: true });
					const fakeModuleList = Object.keys(modules).reduce((list, key) => {
						const module = modules[key] ?? {};
						if (module.default) {
							for (const moduleKey of Object.keys(module)) {
								const mod = modules[key][moduleKey] ?? [];
								const modList = Array.isArray(mod) ? [...mod] : [mod];
								return [...list, ...modList];
							}
						} else {
							return list;
						}
					}, []);
					window.__VITE__PLUGIN__FAKE__SERVER__.fakeModuleList = fakeModuleList;
				