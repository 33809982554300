buttons:
  pureLoginOut: 退出系统
  pureLogin: 登录
  pureOpenSystemSet: 打开系统配置
  pureReload: 重新加载
  pureCloseCurrentTab: 关闭当前标签页
  pureCloseLeftTabs: 关闭左侧标签页
  pureCloseRightTabs: 关闭右侧标签页
  pureCloseOtherTabs: 关闭其他标签页
  pureCloseAllTabs: 关闭全部标签页
  pureContentFullScreen: 内容区全屏
  pureContentExitFullScreen: 内容区退出全屏
  pureClickCollapse: 点击折叠
  pureClickExpand: 点击展开
  pureConfirm: 确认
  pureSwitch: 切换
  pureClose: 关闭
  pureBackTop: 回到顶部
  pureOpenText: 开
  pureCloseText: 关
search:
  pureTotal: 共
  pureHistory: 搜索历史
  pureCollect: 收藏
  pureDragSort: （可拖拽排序）
  pureEmpty: 暂无搜索结果
  purePlaceholder: 搜索菜单（支持拼音搜索）
panel:
  pureSystemSet: 系统配置
  pureCloseSystemSet: 关闭配置
  pureClearCacheAndToLogin: 清空缓存并返回登录页
  pureClearCache: 清空缓存
  pureOverallStyle: 整体风格
  pureOverallStyleLight: 浅色
  pureOverallStyleLightTip: 清新启航，点亮舒适的工作界面
  pureOverallStyleDark: 深色
  pureOverallStyleDarkTip: 月光序曲，沉醉于夜的静谧雅致
  pureOverallStyleSystem: 自动
  pureOverallStyleSystemTip: 同步时光，界面随晨昏自然呼应
  pureThemeColor: 主题色
  pureLayoutModel: 导航模式
  pureVerticalTip: 左侧菜单，亲切熟悉
  pureHorizontalTip: 顶部菜单，简洁概览
  pureMixTip: 混合菜单，灵活多变
  pureStretch: 页宽
  pureStretchFixed: 固定
  pureStretchFixedTip: 紧凑页面，轻松找到所需信息
  pureStretchCustom: 自定义
  pureStretchCustomTip: 最小1280、最大1600
  pureTagsStyle: 页签风格
  pureTagsStyleSmart: 灵动
  pureTagsStyleSmartTip: 灵动标签，添趣生辉
  pureTagsStyleCard: 卡片
  pureTagsStyleCardTip: 卡片标签，高效浏览
  pureTagsStyleChrome: 谷歌
  pureTagsStyleChromeTip: 谷歌风格，经典美观
  pureInterfaceDisplay: 界面显示
  pureGreyModel: 灰色模式
  pureWeakModel: 色弱模式
  pureHiddenTags: 隐藏标签页
  pureHiddenFooter: 隐藏页脚
  pureMultiTagsCache: 页签持久化
menus:
  pureHome: 首页
  pureLogin: 登录
  pureAbnormal: 异常页面
  pureFourZeroFour: "404"
  pureFourZeroOne: "403"
  pureFive: "500"
  purePermission: 权限管理
  purePermissionPage: 页面权限
  purePermissionButton: 按钮权限
  developerButton: 开发者
  programPackageButton: 程序包管理
  developerPkgUpgradButton: 程序包升级
  productButton: 产品管理
  productStacksButton: 产品分类
  productProductButton: 产品
  productFeaturnTypeButton: 功能类型
  productBrandButton: 品牌
  developerFeatureButton: 功能应用
  developerFeatureAppButton: 应用
  systemManageButton: 系统管理
  menuManageButton: 菜单
  psnManageButton: 产品栈节点
status:
  pureLoad: 加载中...
  pureMessage: 消息
  pureNotify: 通知
  pureTodo: 待办
  pureNoMessage: 暂无消息
  pureNoNotify: 暂无通知
  pureNoTodo: 暂无待办
login:
  pureUsername: 账号
  purePassword: 密码
  pureLogin: 登录
  pureLoginSuccess: 登录成功
  pureLoginFail: 登录失败
  pureUsernameReg: 请输入账号
  purePassWordReg: 请输入密码
  purePassWordRuleReg: 密码格式应为8-18位数字、字母、符号的任意两种组合
