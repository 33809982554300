import { http } from "@/utils/http";
import type { IResponseResult } from "../types";
import type { PureHttpRequestConfig } from "@/utils/http/types";
import { assign } from "lodash";
import { formatToken, getToken } from "@/utils/auth";

export type UserResult = {
  success: boolean;
  data: {
    /** 头像 */
    avatar: string;
    /** 用户名 */
    username: string;
    /** 昵称 */
    nickname: string;
    /** 当前登录用户的角色 */
    roles: Array<string>;
    /** `token` */
    accessToken: string;
    /** 用于调用刷新`accessToken`的接口时所需的`token` */
    refreshToken: string;
    /** `accessToken`的过期时间（格式'xxxx/xx/xx xx:xx:xx'） */
    expires: Date;
  };
};

export type RefreshTokenResult = {
  success: boolean;
  data: {
    /** `token` */
    accessToken: string;
    /** 用于调用刷新`accessToken`的接口时所需的`token` */
    refreshToken: string;
    /** `accessToken`的过期时间（格式'xxxx/xx/xx xx:xx:xx'） */
    expires: Date;
  };
};

export interface ILoginResult {
  access_token: string;
  created_at?: number;
  expires_at: number;
  expires_in: number;
  id?: number;
  last_login_at?: number;
  name?: string;
  permissions?: string[];
  refresh_expires_at?: number;
  refresh_expires_in?: number;
  refresh_token: string;
  roles?: string[];
  status?: number;
  updated_at?: number;
  token_type?: string;
  wecom_user_id?: string;
}

export type IRefreshResult = Pick<
  ILoginResult,
  "access_token" | "expires_at" | "expires_in" | "refresh_token"
>;

export interface ILoginParams {
  code: string;
}

export type ILoginApiResult = IResponseResult<ILoginResult>;

let orgParams = {
  client_type: "lonsdor_center"
};

/** 登录 */
export const getLogin = (data?: object) => {
  return http.request<UserResult>("post", "/login", { data });
};

/** 刷新`token` */
export const refreshTokenApi = (
  data?: object,
  option?: PureHttpRequestConfig
) => {
  return http.post<IResponseResult<IRefreshResult>, any>(
    "/user/login/refreshToken",
    {
      data: orgParams
    },
    option
  );
};

/** 登录 */
export const loginApi = (params: ILoginParams) => {
  return http.post<ILoginApiResult, ILoginParams>("/user/login/login", {
    data: assign(orgParams, params)
  });
};

/** 刷新`token` */
export const refreshToken = (params: ILoginParams) => {
  return http.post("/user/login/refreshToken", {
    data: assign(orgParams, params)
  });
};

/** 登出 */
export const logoutApi = () => {
  const data = getToken();
  let headers = {};
  if (data) {
    headers["Authorization"] = formatToken(data.access_token);
  }

  return http.get(
    "/user/login/logout",
    {},
    {
      headers
    }
  );
};
