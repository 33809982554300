import { defineStore } from "pinia";
import {
  type userType,
  store,
  router,
  resetRouter,
  routerArrays,
  storageLocal
} from "../utils";
import {
  type RefreshTokenResult,
  refreshTokenApi,
  type ILoginResult,
  type ILoginApiResult,
  logoutApi,
  type IRefreshResult
} from "@/api/user/login";
import { useMultiTagsStoreHook } from "./multiTags";
import {
  type DataInfo,
  setToken,
  removeToken,
  userKey,
  formatToken
} from "@/utils/auth";
import { loginApi } from "@/api/user/login";
import type { IResponseResult } from "@/api/types";

export const useUserStore = defineStore({
  id: "pure-user",
  state: (): userType => ({
    // 头像
    avatar: storageLocal().getItem<DataInfo<number>>(userKey)?.avatar ?? "",
    // 用户名
    username: storageLocal().getItem<DataInfo<number>>(userKey)?.username ?? "",
    // 昵称
    nickname: storageLocal().getItem<DataInfo<number>>(userKey)?.nickname ?? "",
    // 页面级别权限
    roles: storageLocal().getItem<DataInfo<number>>(userKey)?.roles ?? [],
    // 是否勾选了登录页的免登录
    isRemembered: false,
    // 登录页的免登录存储几天，默认7天
    loginDay: 7
  }),
  actions: {
    /** 存储头像 */
    SET_AVATAR(avatar: string) {
      this.avatar = avatar;
    },
    /** 存储用户名 */
    SET_USERNAME(username: string) {
      this.username = username;
    },
    /** 存储昵称 */
    SET_NICKNAME(nickname: string) {
      this.nickname = nickname;
    },
    /** 存储角色 */
    SET_ROLES(roles: Array<string>) {
      this.roles = roles;
    },
    /** 存储是否勾选了登录页的免登录 */
    SET_ISREMEMBERED(bool: boolean) {
      this.isRemembered = bool;
    },
    /** 设置登录页的免登录存储几天 */
    SET_LOGINDAY(value: number) {
      this.loginDay = Number(value);
    },
    /** 登入 */
    async loginByUsername(data) {
      return new Promise<ILoginApiResult>((resolve, reject) => {
        loginApi(data)
          .then(res => {
            console.log("登录");
            console.log(res);

            if (res.code === 0) {
              let data: ILoginResult = res.data;
              setToken(data);
              resolve(res);
            } else {
              reject();
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    /** 前端登出（不调用接口） */
    async logOut() {
      /// 调用接口退出登录
      await logoutApi();

      this.username = "";
      this.roles = [];
      removeToken();
      useMultiTagsStoreHook().handleTags("equal", [...routerArrays]);
      resetRouter();
      router.push("/login");
      window.location.reload();
    },
    /** 刷新`token` */
    async handRefreshToken(data) {
      return new Promise<IResponseResult<IRefreshResult>>((resolve, reject) => {
        refreshTokenApi(data, {
          headers: {
            Authorization: formatToken(data.refresh_token)
          }
        })
          .then(res => {
            console.log("刷新token");
            console.log(res);

            if (res.code === 0) {
              let data = res.data;

              if (data) {
                setToken(data);
                resolve(res);
              }
            } else {
              useUserStoreHook().logOut();
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }
});

export function useUserStoreHook() {
  return useUserStore(store);
}
